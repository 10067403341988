@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");
.main {
  min-height: 100vh;
  font-family: "Open Sans", sans-serif; }

input {
  background: transparent; }

.sorter {
  display: flex;
  justify-content: space-between;
  width: 170px;
  margin-bottom: 20px; }
  .sorter h4 {
    padding-bottom: 3px;
    cursor: pointer;
    transform: .3s; }
  .sorter .active {
    border-bottom: 3px solid #fab420; }

.previous-comment {
  max-width: 300px; }
  .previous-comment .content {
    border-radius: 5px;
    padding: 10px 20px;
    background: #ebedf5;
    margin: 10px 0; }
  .previous-comment .author {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    opacity: .6;
    padding-right: 20px; }

.pre-save {
  text-align: center;
  padding-top: 50px;
  opacity: .5; }

.messages {
  display: grid;
  padding-top: 50px; }
  .messages h2 {
    font-size: 22px;
    font-weight: 400;
    opacity: .7;
    text-align: center;
    padding-bottom: 20px; }

.current-messages {
  padding-bottom: 50px; }

.btn-submit {
  background: #005fff;
  cursor: pointer;
  border-radius: 25px;
  color: white;
  text-align: center;
  padding: 15px;
  transition: .3s; }
  .btn-submit:hover {
    transition: .2s;
    filter: brightness(110%); }

.phase-list {
  background: white; }

.naked-input {
  width: 100%;
  margin-top: 10px !important; }
  .naked-input div:before {
    border: 0px solid white !important; }

.fade-left {
  transform: translateX(-200%);
  position: absolute;
  width: 87%;
  transition: 0.5s ease-in-out; }

.fade-enter {
  opacity: 0.01; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in; }

.fade-exit {
  opacity: 1;
  position: absolute; }

.fade-exit-active {
  opacity: 0.01; }

.new-project {
  border: 1px solid #c7c8cb;
  padding: 5px 20px;
  border-radius: 50px;
  color: gray;
  cursor: pointer;
  transition: 0.2s; }
  .new-project:hover {
    background: #fab420;
    color: white;
    border: 1px solid #fab420; }
  .new-project span {
    font-weight: bold; }

.new-phase {
  border: 1px solid #c7c8cb;
  border-radius: 5px;
  padding: 50px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  transition: 0.2s;
  cursor: pointer; }

.header-bar img {
  height: 50px; }

.editor {
  display: flex;
  padding: 0 25px 25px 25px; }

#outlined-age-native-simple {
  background: #fab420;
  color: white; }

.info-box {
  color: white;
  display: flex;
  padding: 10px;
  justify-content: space-between; }
  .info-box .phase-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold; }
  .info-box .icon-bar {
    width: 80px;
    align-items: center;
    color: #c7c8cb;
    display: flex;
    justify-content: flex-end; }
    .info-box .icon-bar input {
      width: 45px; }
    .info-box .icon-bar img {
      height: 15px;
      opacity: 0.3;
      padding-right: 10px;
      cursor: pointer;
      transition: 0.2s; }
      .info-box .icon-bar img:hover {
        opacity: 0.7; }

input[type="number"] {
  border: 0;
  font-size: 16px;
  text-align: right; }

input:focus {
  outline: none; }

.phase-container {
  margin: 10px 10px 50px 0;
  flex-basis: 380px;
  flex-grow: 1; }

.new-item {
  padding: 20px 36px;
  transition: 0.2s;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer; }
  .new-item:hover {
    color: rgba(0, 0, 0, 0.7); }

.phase-item {
  position: relative;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .phase-item img {
    cursor: pointer; }
    .phase-item img:hover {
      opacity: 1; }
  .phase-item:first-child {
    padding-top: 20px; }
  .phase-item:last-child {
    padding-bottom: 20px; }
  .phase-item .description {
    display: flex;
    align-items: center; }

.default-editor {
  flex-grow: 1;
  background: #eaedf5; }

.project-defaults {
  justify-content: space-between;
  width: 90%;
  padding-top: 40px;
  margin: 0 auto; }
  .project-defaults .flex-container {
    align-items: flex-start; }

.project-list {
  display: flex;
  flex-wrap: wrap;
  width: 290px;
  transition: 0.3s; }
  .project-list .active {
    transition: 0.3s;
    background: black;
    color: white; }
  .project-list .button {
    width: 100%;
    text-align: center;
    margin-top: 10px; }
  .project-list li {
    cursor: pointer;
    width: 100%;
    list-style-type: none;
    padding: 17px;
    border-bottom: 1px solid #c7c8cb;
    border-left: 1px solid #c7c8cb;
    border-right: 1px solid #c7c8cb;
    transition: 0.3s; }
    .project-list li:hover {
      background: #fab420; }
    .project-list li:first-child {
      border-top: 1px solid #c7c8cb; }

.socialite {
  width: 90%;
  padding-top: 50px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap; }
  .socialite .social-toolbar {
    flex-grow: 1;
    flex-basis: 30%;
    padding: 20px; }
  .socialite .social-canvas {
    flex-grow: 1;
    flex-basis: 60%; }
  .socialite .format-chooser {
    flex-grow: 1;
    width: 100%;
    padding-bottom: 30px;
    text-align: center; }
    .socialite .format-chooser .active {
      border-bottom: 2px solid #fab420; }
    .socialite .format-chooser img.active {
      opacity: 0.6; }
    .socialite .format-chooser img {
      cursor: pointer;
      width: 20px;
      opacity: 0.3;
      padding-bottom: 10px;
      transition: 0.3s; }
      .socialite .format-chooser img:hover {
        opacity: 1; }
      .socialite .format-chooser img:first-child {
        margin-right: 20px; }

.workspace {
  flex-grow: 1; }
  .workspace .flex-container {
    justify-content: space-between; }
  .workspace .phase-box {
    border-radius: 5px;
    box-shadow: 0 0 100px -20px rgba(0, 0, 0, 0.2); }

.top-labels {
  border-top: 3px solid #fab420;
  padding: 15px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  background: #f7f8fc;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  display: flex;
  justify-content: space-between; }

.calculator-panel {
  position: relative;
  margin-right: 30px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 100px -20px rgba(0, 0, 0, 0.2);
  width: 30%;
  min-width: 350px;
  min-height: 90vh; }
  .calculator-panel .top-labels div:first-child {
    width: 50%; }
  .calculator-panel .top-labels div:nth-child(2), .calculator-panel .top-labels div:nth-child(3) {
    width: 25%; }
  .calculator-panel .phase-box {
    position: relative;
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    padding: 20px;
    border-top: 1px solid #c7c8cb;
    border-bottom: 1px solid #c7c8cb;
    background: white;
    display: flex;
    justify-content: space-between; }
    .calculator-panel .phase-box div:first-child {
      width: 50%; }
    .calculator-panel .phase-box div:nth-child(2), .calculator-panel .phase-box div:nth-child(3) {
      width: 25%; }
  .calculator-panel .bottom-labels {
    position: absolute;
    bottom: 0;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    width: 100%;
    border-top: 1px solid #c7c8cb;
    background: #f7f8fc;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .calculator-panel .bottom-labels .bottom-container {
      width: 100%;
      padding: 20px; }
      .calculator-panel .bottom-labels .bottom-container:first-child {
        border-bottom: 1px solid #c7c8cb; }
    .calculator-panel .bottom-labels .flex-container {
      justify-content: space-between; }
    .calculator-panel .bottom-labels span {
      font-weight: normal; }

.center-content {
  height: 100vh;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; }
  .center-content p {
    padding-bottom: 15px; }

.top-sort {
  width: 92%;
  padding-left: 34px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 800;
  color: gray;
  padding-bottom: 20px; }
  .top-sort .column {
    padding: 0; }

.divider {
  border-bottom: 1px solid lightgray;
  padding: 10px 0; }

span.save-title {
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: bold;
  letter-spacing: 2px;
  color: #b1b1b1; }

.view-controls {
  display: flex; }

.save-ui {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.load {
  padding: 10px;
  background: lightgreen;
  color: black;
  border-radius: 5px;
  margin-right: 20px; }

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem; }

.search-bar {
  width: 30%;
  display: flex;
  align-items: center;
  margin: 20px 10px 50px 0;
  min-width: 300px; }
  .search-bar input {
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #dedede; }
  .search-bar i {
    margin-left: 10px; }

.save-database {
  background: white;
  border-bottom: 1px solid lightgray;
  color: gray;
  padding: 8px;
  border-radius: 5px; }

.save-box {
  width: 98%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .save-box .columns {
    width: 90%;
    margin: 0; }

.menu-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
  opacity: 1;
  transition: 0.3s; }

.menu-overlay.hidden {
  opacity: 0; }

.button {
  text-decoration: none;
  color: black;
  cursor: pointer;
  box-shadow: 0 0 100px -20px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;
  border-radius: 5px; }

.is-primary {
  background: #fab420; }

.sidebar-menu {
  transition: 0.3s;
  top: 0;
  left: 0;
  height: 100vh;
  background: white;
  width: 300px;
  box-shadow: 6px 0px 20px 5px rgba(0, 0, 0, 0.2); }
  .sidebar-menu a {
    text-decoration: none;
    color: #333;
    font-family: sans-serif; }
  .sidebar-menu .menu-item {
    border-bottom: 1px solid #c7c8cb; }
    .sidebar-menu .menu-item:last-child {
      border-top: 0px solid white; }
    .sidebar-menu .menu-item .menu-container {
      padding: 20px;
      display: flex;
      align-items: center; }
    .sidebar-menu .menu-item span {
      width: 25px;
      opacity: 0.4;
      margin-right: 10px; }

.flex-container {
  display: flex;
  flex-wrap: wrap; }

.align-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 768px; }

.touches-header {
  padding-top: 125px; }

.overlay {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute; }

.hero {
  position: relative; }

body {
  background: #f7f8fc; }

.menu-icon {
  width: 20px;
  margin-left: 10px; }

header .flex-container {
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto; }

header .left-bar {
  display: flex;
  align-items: center; }

header .toolbar {
  display: flex;
  align-items: center; }
  header .toolbar img {
    opacity: 0.5;
    height: 20px;
    margin-right: 20px;
    cursor: pointer; }
    header .toolbar img:hover {
      opacity: 1; }

header .logo {
  margin-right: 20px;
  width: 100px; }
  header .logo img {
    width: 100%; }

header .left {
  display: flex;
  align-items: center;
  width: 50%; }

header nav {
  padding-left: 30px; }
  header nav a {
    padding-right: 20px; }
    header nav a:last-child {
      padding-right: 0; }

header a {
  text-decoration: none;
  color: inherit; }

.user-upload {
  background: white;
  padding: 40px; }

.avatar-upload {
  display: flex;
  justify-content: center;
  margin: 40px; }

.avatar-preview {
  display: flex;
  position: relative;
  justify-content: center;
  margin: 20px; }
  .avatar-preview .uploader {
    position: absolute;
    right: 0px;
    top: 6px;
    font-size: 23px; }

.user-form {
  padding-top: 100px;
  height: 100vh;
  min-height: 768px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }

.login-container {
  background: #333;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 768px; }

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  background: white;
  padding: 100px;
  position: relative;
  width: 90%; }

.active-auth,
.inactive-auth {
  display: flex;
  align-items: center;
  justify-content: center; }
  .active-auth:first-child,
  .inactive-auth:first-child {
    border-right: 1px solid lightgray; }

.inactive-auth {
  cursor: pointer;
  background: whitesmoke;
  transition: 0.4s;
  border-bottom: 1px solid lightgray; }
  .inactive-auth h2 {
    color: lightgray; }

.library-container {
  width: 90%;
  margin: 0 auto;
  max-width: 1400px; }

.form-container.login-form {
  width: 100%; }
  .form-container.login-form .column {
    flex-grow: 1; }
  .form-container.login-form input.is-primary.button {
    background: #fab420;
    color: black;
    padding: 15px;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px; }
  .form-container.login-form .chooser {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    display: flex; }
    .form-container.login-form .chooser h2 {
      font-size: 1.1em;
      color: gray;
      text-align: center;
      padding: 0; }

p.form-error {
  color: red;
  padding-top: 30px; }

.checklist {
  margin-top: 50px;
  position: relative; }
  .checklist .container {
    margin-bottom: 30px;
    background: white;
    box-shadow: 0 0 100px -20px rgba(0, 0, 0, 0.2); }
  .checklist fieldset {
    padding: 20px; }
  .checklist .banner {
    display: flex;
    padding: 15px;
    align-items: center;
    width: 100%;
    background: #fab420; }
    .checklist .banner h2 {
      margin: 0;
      padding-left: 20px;
      line-height: 0; }

.padding-top {
  padding-top: 50px; }
